import { StoreConfig, Store } from '@datorama/akita';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {
  AkitaRouterState,
  createInitialState,
  AnalyticsMetadata,
  NavigationInfo,
} from '@app/akita/router/models/router.state';
import { Params } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
/* import { REQUEST } from '../../../../express.tokens';
 */ import { WindowUtils } from '@app/shared/utils/window.util';
import { UserModel } from '@app/shared/models/api/user.model';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { PriceModel } from '@app/shared/models/api/price.model';

const PREFIX_DATA_TO_URL = true;

const PRESERVE_QUERY_PARAM_KEYS: Array<string> = [
  'lang',
  'country',
  'gclsrc',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'adgroup',
  'network',
  'device',
  'merchant_id',
  'product_id',
  'gclid',
  'dclid',
  'aclid',
  'anid',
  'cp1',
  'dc',
  'tabby',
  'stripe',
  'cko',
  'tamara',
  'quara',
  'omnisendAnonymousID',
  'omnisendSessionID',
  'omnisendAttributionID',
  'omnisendContactID',
  'omnisendDebugConsole',
  'omnisendCartProducts',
  'soundest-product-picker',
  'soundest-views',
  'soundestID',
  't', // used for a/b testing
  'is_abandoned', // used for abandoned cart / pick it up where you left it
];

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'angular-router', resettable: false })
export class AkitaRouterStore extends Store<AkitaRouterState> {
  public readonly isBrowser: boolean;
  private readonly clientRequest: any | null;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Record<string, any>) {
    super(createInitialState());

    this.isBrowser = isPlatformBrowser(this.platformId);

    if (!this.isBrowser) {
      try {
        /*         this.clientRequest = this.injector.get(REQUEST);
         */
      } catch (error) {
        this.clientRequest = null;
      }
    }
  }

  public get requestURL(): string {
    let url = WindowUtils.getLocationHref();
    if (this.clientRequest) {
      url = `${this.clientRequest.get('host')}${this.clientRequest.originalUrl}`;
    }
    return url;
  }

  public onNavigationEnd(
    params?: Params | null,
    queryParams?: Params | null,
    data?: Params | null,
    fragment?: string | null
  ): void {
    const routeData = data || {};
    const routeParams = params || {};
    const routeQueryParams = queryParams || {};
    const fragmentParam = fragment || '';
    let userAgent: string | null;
    try {
      userAgent = (window as any)?.navigator.userAgent || null;
    } catch (e) {
      userAgent = null;
    }

    const queryParamsToKeep: Params = {};
    for (const key of Object.keys(routeQueryParams)) {
      if (PRESERVE_QUERY_PARAM_KEYS.indexOf(key) !== -1) {
        queryParamsToKeep[key] = routeQueryParams[key];
      }
    }

    this.update((state: AkitaRouterState) => ({
      params: routeParams || {},
      queryParams: routeQueryParams || {},
      queryParamsToKeep: queryParamsToKeep || {},
      data: routeData || {},
      fragment: fragmentParam || '',
      userAgent: userAgent,

      rtl: Boolean(routeData.rtl),
      internalView: Boolean(routeData.internal),
      isOwnProfile: this.detectOwnProfile(
        state.sessionUser,
        routeParams,
        routeQueryParams,
        routeData
      ),

      redirect: decodeURI(routeQueryParams.redirect || routeParams.redirect || ''),

      view: routeData.view || '',
      step: routeData.step || '',

      routeFragments: {
        homeUrlFragment: this.generateHomeUrlFragment(routeData),
      },

      analyticsMetadata: this.proccessGoogleADsMetadata(routeQueryParams || {}),

      discountCode: routeQueryParams.dc || state.discountCode,

      lastUpdated: new Date(),
    }));

    if (queryParams?.dc) {
      this.update(() => ({
        discountCode: queryParams.dc,
      }));
    }
  }

  public addParamToKeepQueryParams(params?: Params | null): void {
    this.update((state: AkitaRouterState) => ({
      queryParams: { ...(state.queryParams || {}), ...(params || {}) },
      queryParamsToKeep: { ...(state.queryParamsToKeep || {}), ...(params || {}) },
    }));
  }

  public removeParamToKeepQueryParams(param: string): void {
    this.update((state: AkitaRouterState) => {
      const queryParams = { ...(state.queryParams || {}) };
      const queryParamsToKeep = { ...(state.queryParamsToKeep || {}) };

      delete queryParams[param];
      delete queryParamsToKeep[param];

      return {
        queryParams: queryParams,
        queryParamsToKeep: queryParamsToKeep,
      };
    });
  }

  private proccessGoogleADsMetadata(queryParams: Params): AnalyticsMetadata {
    const productIDs = new Array(0);
    if (queryParams.product_id) {
      if (typeof queryParams.product_id === 'string') {
        productIDs.push(queryParams.product_id);
      } else {
        for (const item of queryParams.product_id) {
          productIDs.push(item);
        }
      }
    }

    return {
      gclsrc: queryParams.gclsrc || null,
      utmSource: queryParams.utm_source || queryParams.utmSource || null,
      utmMedium: queryParams.utm_medium || queryParams.utmMedium || null,
      utmCampaign: queryParams.utm_campaign || queryParams.utmCampaign || null,
      utmContent: queryParams.utm_content || queryParams.utmContent || null,
      utmTerm: queryParams.utm_term || queryParams.utmTerm || null,
      adgroup: queryParams.adgroup || null,
      network: queryParams.network || null,
      device: queryParams.device || null,
      merchantId: queryParams.merchant_id || queryParams.merchantId || null,
      productIds: productIDs,
      gclid: queryParams.gclid || null,
      dclid: queryParams.dclid || null,
      aclid: queryParams.aclid || null,
      anid: queryParams.anid || null,
      cp1: queryParams.cp1 || null,
    };
  }

  private generateHomeUrlFragment(data: Params): Array<string> {
    const fragment = ['/'];
    // if (data && data.locale) {
    //   const country = SUPPORTED_LOCALES[data.locale] || '';
    //   if (country) {
    //     fragment.push(`${data.locale}-${country}`);
    //   } else {
    //     getBaseCanonicalURL();
    //     fragment.push(data.locale);
    //   }
    // }

    if (PREFIX_DATA_TO_URL && data && (data.locale || data.country)) {
      const country = `${data?.country || ''}`.toLowerCase();
      const language = `${data?.locale || ''}`.toLowerCase();

      if (language && country) {
        fragment.push(`${language}-${country}`);
      } else {
        fragment.push(language || country);
      }
    }
    return fragment;
  }

  private detectOwnProfile(
    authUser: UserModel | null,
    params: Params,
    queryParams: Params,
    data: Params
  ): boolean {
    // Get the User ID from the params (or query params)
    let ownProfile = false;
    const routeUserId = data.userId || params.id || queryParams.id || '';

    // If there is a user in the route data, and no ID in params or the ID is same
    // this is a user looking at its own profile.
    if (
      data?.user &&
      (!data?.user?.id || (routeUserId && data?.user?.id === routeUserId))
    ) {
      ownProfile = true;
    } else if (
      authUser &&
      ((!routeUserId && authUser?.id) || (routeUserId && authUser?.id === routeUserId))
    ) {
      ownProfile = true;
    } else if (routeUserId) {
      // If the url is a public page, it won't have the user auth info in the route (but user still might be authenticated)
      if (authUser && (authUser?.id === routeUserId || routeUserId === '')) {
        ownProfile = true;
      }
    }

    return Boolean(ownProfile);
  }

  public updateSessionUser(user?: UserModel | null): void {
    this.update({
      sessionUser: user || null,
    });
  }

  public setDiscountCode(discountCode: string): void {
    this.update({
      discountCode: discountCode,
    });
  }

  public toggleDiscountLoading(loading?: boolean | null): void {
    this.update({
      validatingDiscountCode: Boolean(loading),
    });
  }

  public setDiscountForListing(
    discountCode?: string | null,
    listingId?: string | null,
    discount?: PriceModel | null
  ): void {
    if (discountCode && listingId) {
      this.update((state: AkitaRouterState) => {
        const discountCodeValues = { ...(state.discountCodeValues || {}) };
        discountCodeValues[discountCode] = discountCodeValues[discountCode] || {};
        discountCodeValues[discountCode][listingId] = discount || null;

        return {
          discountCodeValues: discountCodeValues,
        };
      });
    }
  }

  public setDiscountLoadingError(error?: ApiError | null): void {
    this.update({
      errorValidatingDiscountCode: error || null,
    });
  }

  public setNavigation(navigation: NavigationInfo): void {
    this.update({
      navigation: navigation,
    });
  }
}
