import { Injectable, NgZone } from '@angular/core';
import { UserModel } from '@app/shared/models/api/user.model';
import { PictureModel } from '@app/shared/models/api/picture.model';
import { AkitaAuthStore } from './auth.store';
import { AkitaAuthQuery } from './auth.query';
import { environment } from '@environments/environment';
import { resetStores, applyTransaction, logAction } from '@datorama/akita';
import {
  throwError,
  Observable,
  EMPTY,
  Subscription,
  of,
  catchError,
  tap,
  switchMap,
  map,
} from 'rxjs';
import { AuthTokenModel } from '@app/shared/models/api/auth/auth-token.model';
import { configureScope } from '@sentry/browser';
import { AuthAPIService } from '@app/akita/api/auth/services/auth.service';
import { HttpStatusCode } from '@app/shared/utils/http-status-codes';
import { CookieService } from '@app/shared/services/cookie.service';
import { addWeeks } from 'date-fns';
import { ApiCallsTrackerService } from '@app/core/services/api-calls-tracker.service';
import { SocialAuthInfo } from '../models/social-auth-info.model';
import { SignWithAppleService } from '../services/sign-with-apple.service';
import { SignWithGoogleService } from '../services/sign-with-google.service';
import { parseApiError } from '@app/shared/models/api/api-error.model';
import { SignWithFacebookService } from '../services/sign-with-facebook.service';
import {
  VerifyUserModel,
  VerifyUserStatus,
} from '@app/shared/models/api/auth/verify-user.model';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';

@Injectable({ providedIn: 'root' })
export class AkitaAuthService {
  private renewingToken: boolean;

  private readonly subscriptions: Subscription;

  constructor(
    private readonly zone: NgZone,
    private readonly query: AkitaAuthQuery,
    private readonly store: AkitaAuthStore,
    private readonly cookieService: CookieService,
    private readonly authAPIService: AuthAPIService,
    private readonly signWithAppleService: SignWithAppleService,
    private readonly signWithGoogleService: SignWithGoogleService,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly signWithFacebookService: SignWithFacebookService,
    private readonly apiCallsTrackerService: ApiCallsTrackerService
  ) {
    this.renewingToken = false;

    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.signWithGoogleService.observeOneTap.subscribe({
        next: (data: { clientId: string; credential: string; select_by: string }) => {
          this.signWithGoogleStep2Async(data.credential);
        },
      })
    );
  }

  public clearSubscriptions(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  public showOneTap(): void {
    if (!this.query.isLoggedIn && this.akitaRouterQuery.viewStep !== 'CHECKOUT_SESSION') {
      this.signWithGoogleService.oneTapSignIn();
    }
  }

  public setSessionId(sessionId?: string | null): void {
    configureScope((scope) => {
      scope.setExtra('psid', `${sessionId || '??'}`);
    });

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setSessionId()');
        this.store.setSessionId(sessionId);
      });
    });
  }

  public resetSocialAuthErrors(): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('resetSocialAuthErrors()');
        this.store.setSignWithAppleError(null);
        this.store.setSignWithGoogleError(null);
        this.store.setSignWithFacebookError(null);
      });
    });
  }

  public checkGoogleAuthInitialization(): void {
    // Platform.js no longer used (Issues with Cookies)
    this.googleAuthSDKLoaded(true);
  }

  private googleAuthSDKLoaded(hasLoaded?: boolean | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('googleAuthSDKLoaded()');
        this.store.toggleSigningInWithGoogleSDKLoaded(hasLoaded);
      });
    });
  }

  public updateCookieSession(user: UserModel | null): void {
    // Update the cookie session so the session persists between subdomains
    let domain: string | undefined;
    let secure: boolean | undefined;

    if (environment.production) {
      domain = 'popsy.app';
      try {
        domain = window.location.hostname;
      } catch (error) {
        console.error(error);
      }
      secure = true;
    }

    if (user && user.oauth && user.oauth.token) {
      this.cookieService.set(
        `popsy_pid_${environment.name || 'local'}`,
        `${user.oauth.publicId || ''}`,
        addWeeks(new Date(), 1),
        '/',
        domain,
        secure,
        'Lax'
      );

      this.cookieService.set(
        `popsy_sec_${environment.name || 'local'}`,
        `${user.oauth.secret || ''}`,
        addWeeks(new Date(), 1),
        '/',
        domain,
        secure,
        'Lax'
      );

      this.zone.run(() => {
        applyTransaction(() => {
          logAction('updateCookieSession()');
          this.store.setCookieSession(
            `${user.oauth.publicId || ''}` || null,
            `${user.oauth.secret || ''}` || null
          );
        });
      });
    } else if (user === null) {
      this.cookieService.set(
        `popsy_pid_${environment.name || 'local'}`,
        '',
        new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
        '/',
        domain,
        secure,
        'Lax'
      );

      this.cookieService.set(
        `popsy_sec_${environment.name || 'local'}`,
        '',
        new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
        '/',
        domain,
        secure,
        'Lax'
      );

      this.zone.run(() => {
        applyTransaction(() => {
          logAction('updateCookieSession()');
          this.store.setCookieSession(null, null);
        });
      });
    }
  }

  public checkCookieSession(): void {
    let cookiePidToken: string | null = null;
    let cookieSecToken: string | null = null;

    if (this.cookieService.check(`popsy_pid_${environment.name || 'local'}`)) {
      cookiePidToken = this.cookieService.getLatest(
        `popsy_pid_${environment.name || 'local'}`
      );
    }

    if (this.cookieService.check(`popsy_sec_${environment.name || 'local'}`)) {
      cookieSecToken = this.cookieService.getLatest(
        `popsy_sec_${environment.name || 'local'}`
      );
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('checkCookieSession()');
        this.store.setCookieSession(cookiePidToken || null, cookieSecToken || null);

        // Check if the user has logged in already
        const cookieSession = this.query.cookieSession;
        if (!this.query.user && cookiePidToken && cookieSecToken) {
          if (cookieSession.pid && cookieSession.sec) {
            const cookieUser = new UserModel();
            cookieUser.oauth.publicId = cookiePidToken;
            cookieUser.oauth.secret = cookieSecToken;
            this.store.setUser(cookieUser);
            this.renewSession();
          }
        }
      });
    });
  }

  public registerUserInBackground(
    email: string,
    username: string,
    password?: string | null,
    locale?: string | null
  ): Observable<UserModel | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('registerUserInBackground()');
        this.store.toggleRegisteringInBackground(true);
        this.store.setRegisteringInBackgroundError(null);
      });
    });

    return this.authAPIService
      .signUpInBackground(email, username || '', password, locale)
      .pipe(
        switchMap((user: UserModel | null) => {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('registerUserInBackground() - done');
              this.store.toggleRegisteringInBackground(false);
              this.userSignedIn(user);
            });
          });

          return this.renewSessionSync().pipe(
            map((token: AuthTokenModel | null): UserModel | null => (token ? user : null))
          );
        }),
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('registerUserInBackground() - error');
              this.store.toggleRegisteringInBackground(false);
              this.store.setRegisteringInBackgroundError(parsed);
            });
          });
          return throwError(() => parsed);
        })
      );
  }

  public register(
    email: string,
    username?: string | null,
    password?: string | null,
    locale?: string | null
  ): Observable<UserModel | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('register()');
        this.store.toggleRegistering(true);
        this.store.setRegisteringError(null);
      });
    });

    return this.authAPIService
      .signUp(username || '', email, password || '', locale || 'en-US')
      .pipe(
        tap((user: UserModel | null) => {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('register() - done');
              this.store.toggleRegistering(false);
              this.userSignedIn(user);
            });
          });
        }),
        catchError((error: unknown) => {
          const parsed = parseApiError(error);
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('register() - error');
              this.store.toggleRegistering(false);
              this.store.setRegisteringError(parsed);
            });
          });
          return throwError(() => parsed);
        })
      );
  }

  public checkIfEmailIsValid(email: string): Observable<VerifyUserModel | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('checkIfEmailIsValid()');
        this.store.toggleCheckingIfEmailValid(true);
        this.store.setCheckingIfEmailValidError(null);
      });
    });

    return this.authAPIService.verifyUserDetails(email).pipe(
      tap((details: VerifyUserModel | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('checkIfEmailIsValid() - done');
            this.store.toggleCheckingIfEmailValid(false);
            this.store.updateEmailStatus(
              email,
              details?.email || VerifyUserStatus.NOT_VALID
            );
          });
        });
      }),
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('checkIfEmailIsValid() - error');
            this.store.toggleCheckingIfEmailValid(false);
            this.store.setCheckingIfEmailValidError(parsed);
          });
        });
        return throwError(() => parsed);
      })
    );
  }

  public logout(): void {
    this.signWithGoogleService.disableOneTapAutoSelect();
    this.clearSession();
  }

  public setUser(user: UserModel | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setUser()');
        this.updateCookieSession(user);
        this.store.setUser(user);
      });
    });
  }

  public updateOptIn(optIn: boolean | null): Observable<boolean> {
    const user = this.query.user;
    if (user) {
      user.opt_in = optIn;
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('udateProfileInformation() - done');
          this.updateCookieSession(user);
          this.store.setUser(user);
        });
      });

      return of(this.query.user?.opt_in || false);
    }
    return of(false);
  }

  public updateListingsCount(count: number): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateListingsCount()');
        this.store.updateListingsCount(count);
      });
    });
  }

  public updateFavoritesCount(count: number): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateFavoritesCount()');
        this.store.updateFavoritesCount(count);
      });
    });
  }

  public udateProfileInformation(
    email?: string | null,
    description?: string | null,
    picture?: PictureModel | null
  ): void {
    const user = this.query.user;
    if (user) {
      if (email) {
        user.email = email;
      }

      if (description) {
        user.description = description;
      }

      if (picture) {
        user.picture = picture;
      }

      this.zone.run(() => {
        applyTransaction(() => {
          logAction('udateProfileInformation() - done');
          this.updateCookieSession(user);
          this.store.setUser(user);
        });
      });
    }
  }

  public refreshUserAsync(): Subscription {
    const observer = new Subscription();

    const accessToken = this.query.accessToken;
    const isSessionActive = this.query.isSessionActive;

    const nextFn = (user: UserModel | null): void => {
      const oauth = this.query.oauth;
      if (!oauth || !oauth.publicId || !oauth.secret) {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('refreshUserAsync() - skipped');
            this.store.setError('Skipped User Refresh due to previous Sign Out');
            this.store.updateLastTokenRefresh(true);
          });
        });
      } else {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('refreshUserAsync() - done');
            this.updateCookieSession(user);
            this.store.setUser(user);
            this.store.updateLastTokenRefresh();
          });
        });
      }
    };

    const errFn = (error: unknown) => {
      const parsed = error as any;
      if (parsed?.status === HttpStatusCode.UNAUTHORIZED) {
        if (!this.renewingToken) {
          this.renewSession();
        }
      } else if (parsed?.status === HttpStatusCode.NOT_FOUND) {
        this.logout();
      }
    };

    if (isSessionActive) {
      if (accessToken && this.query.shouldRefreshUser) {
        observer.add(
          this.authAPIService.getUserInfoFromToken(accessToken).subscribe({
            next: nextFn,
            error: errFn,
          })
        );
      }
    } else {
      observer.add(
        this.renewSessionSync()
          .pipe(
            switchMap((authToken: AuthTokenModel | null) =>
              this.authAPIService.getUserInfoFromToken(authToken?.accessToken || '')
            )
          )
          .subscribe({
            next: nextFn,
            error: errFn,
          })
      );
    }

    return observer;
  }

  public userSignedIn(user: UserModel | null): void {
    this.setUser(user);
    if (user) {
      if (user.oauth?.token) {
        this.updateSession(user.oauth.token);
      } else if (user.oauth?.secret && user.oauth?.publicId) {
        this.renewSession();
      }
    }
  }

  public renewSession(): Subscription {
    const out = new Subscription();
    if (!this.renewingToken) {
      out.add(
        this.renewSessionSync().subscribe({
          next: () => {},
          error: () => {},
        })
      );
    }
    return out;
  }

  public renewSessionSync(): Observable<AuthTokenModel | null> {
    const oauth = this.query.oauth;
    const serverType = this.query.serverType;

    // The credentials belong to a different API (Sign Out)
    if (serverType !== environment.api.url) {
      const error = new Error('SESSION_FROM_DIFFERENT_API');
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('renewSession() - init error');
          this.clearSession();
          this.setError(error);
        });
      });
      return throwError(() => error);
    }

    if (!oauth || !oauth.publicId || !oauth.secret) {
      const error = new Error('USER_NOT_LOGGED_IN');
      this.zone.run(() => {
        applyTransaction(() => {
          logAction('renewSession() - init error');
          this.setError(error);
        });
      });
      return throwError(() => error);
    }

    if (this.apiCallsTrackerService.isOngoing('[POST] /oauth/bridge/token')) {
      return EMPTY;
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('renewSession()');
        this.store.update({
          refreshingToken: true,
          serverType: environment.api.url,
          lastUpdated: new Date(),
          error: null,
        });
      });
    });

    this.renewingToken = true;
    return this.authAPIService.getAccessToken(oauth.publicId, oauth.secret).pipe(
      tap((data: AuthTokenModel | null): void => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('renewSession() - done');
            this.updateSession(data);
            this.renewingToken = false;
          });
        });
      }),
      catchError((error: unknown) => {
        const parsed = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('renewSession() - error');
            this.setError(parsed);
            this.clearSession();
            this.renewingToken = false;
          });
        });
        return throwError(() => parsed);
      })
    );
  }

  public expireSession(): void {
    const user = this.query.user;

    if (user && user.oauth && user.oauth.token) {
      user.oauth.token = {
        accessToken: '0000000000000000000000000000000000000000000000000000000000000000',
        expiresIn: 0,
        expiresAt: new Date(0),
      };

      this.zone.run(() => {
        applyTransaction(() => {
          logAction('expireSession()');
          this.updateCookieSession(user);
          this.store.setUser(user);
          this.store.updateLastTokenRefresh(true);
        });
      });
    }
  }

  public initializeAppleAuthAsync(): Subscription {
    return this.initializeAppleAuth().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public initializeAppleAuth(): Observable<boolean | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('initializeAppleAuth()');
        this.store.toggleInitializingSignInWithApple(true);
        this.store.setSignWithAppleError(null);
      });
    });

    return this.signWithAppleService.initialize().pipe(
      catchError((error: unknown) => {
        const parsedError = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('initializeAppleAuth() - error');
            this.store.toggleInitializingSignInWithApple(false);
            this.store.setInitializingSignInWithAppleError(parsedError);
          });
        });
        return throwError(() => parsedError);
      }),
      tap((initialized: boolean) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('initializeAppleAuth() - done');
            this.store.toggleInitializingSignInWithApple(false);
            this.store.setSignInWithAppleInitialized(initialized);
          });
        });
      })
    );
  }

  public signWithAppleAsync(): Subscription {
    return this.signWithApple().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public signWithApple(): Observable<UserModel | null> {
    this.clearSession();
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('signWithApple()');
        this.store.toggleSingingWithApple(true);
        this.store.setSignWithAppleError(null);
      });
    });

    configureScope((scope) => {
      scope.setUser({
        id: 'Apple Login',
        username: '?',
        email: '?',
      });
    });

    return this.signWithAppleService.signIn().pipe(
      catchError((error: unknown) => {
        const parsedError = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithApple() - error');
            this.store.toggleSingingWithApple(false);
            this.store.setSignWithAppleError(parsedError);
          });
        });

        configureScope((scope) => {
          scope.setUser({});
        });

        return throwError(() => parsedError);
      }),
      switchMap((info: SocialAuthInfo | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithApple() - done');
            this.store.toggleSingingWithApple(false);
            this.store.setSignWithAppleInfo(info);
          });
        });

        return this.signWithAppleStep2(info?.secret || '', info?.name || '');
      })
    );
  }

  public signWithGoogleAsync(): Subscription {
    return this.signWithGoogle().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public signWithGoogle(): Observable<UserModel | null> {
    this.clearSession();

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('signWithGoogle()');
        this.store.toggleSingingWithGoogle(true);
        this.store.setSignWithGoogleError(null);
      });
    });

    configureScope((scope) => {
      scope.setUser({
        id: 'Google Login',
        username: '?',
        email: '?',
      });
    });

    return this.signWithGoogleService.signInOauthV2InWindow().pipe(
      // return this.signWithGoogleService.signInOauthV2().pipe(
      // return this.signWithGoogleService.signIn().pipe(
      catchError((error: unknown) => {
        const parsedError = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithGoogle() - error');
            this.store.toggleSingingWithGoogle(false);
            this.store.setSignWithGoogleError(parsedError);
          });
        });

        configureScope((scope) => {
          scope.setUser({});
        });

        return throwError(() => parsedError);
      }),
      switchMap((info: SocialAuthInfo | null) => {
        if (info) {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('signWithGoogle() - done');
              this.store.toggleSingingWithGoogle(false);
              this.store.setSignWithGoogleInfo(info);
            });
          });

          return this.signWithGoogleStep2(info?.secret || '');
        }

        return of(null);
      })
    );
  }

  public signWithFacebookAsync(): Subscription {
    return this.signWithFacebook().subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public signWithFacebook(): Observable<UserModel | null> {
    this.clearSession();

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('signWithFacebook()');
        this.store.toggleSingingWithFacebook(true);
        this.store.setSignWithFacebookError(null);
      });
    });

    configureScope((scope) => {
      scope.setUser({
        id: 'Facebook Login',
        username: '?',
        email: '?',
      });
    });

    return this.signWithFacebookService.signIn().pipe(
      catchError((error: unknown) => {
        const parsedError = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithFacebook() - error');
            this.store.toggleSingingWithFacebook(false);
            this.store.setSignWithFacebookError(parsedError);
          });
        });

        configureScope((scope) => {
          scope.setUser({});
        });

        return throwError(() => parsedError);
      }),
      switchMap((info: SocialAuthInfo | null) => {
        if (info) {
          this.zone.run(() => {
            applyTransaction(() => {
              logAction('signWithFacebook() - done');
              this.store.toggleSingingWithFacebook(false);
              this.store.setSignWithFacebookInfo(info);
            });
          });

          return this.signWithFacebookStep2(info?.userId || '', info?.secret || '');
        }

        const parsed = parseApiError({
          name: 'popup_closed_by_user',
          code: 'popup_closed_by_user',
          message: 'error_social_auth_dialog_closed',
        });

        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithFacebook() - error');
            this.store.toggleSingingWithFacebook(false);
            this.store.setSignWithFacebookError(parsed);
          });
        });

        return throwError(() => parsed);
      })
    );
  }

  private signWithGoogleStep2Async(secret: string): Subscription {
    return this.signWithGoogleStep2(secret).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  public signWithGoogleStep2(secret: string): Observable<UserModel | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('signWithGoogleStep2()');
        this.store.toggleSingingWithGoogleStep2(true);
        this.store.setSignWithGoogleStep2Error(null);
      });
    });

    return this.authAPIService.signInWithGoogle(secret || '').pipe(
      catchError((error: unknown) => {
        const parsedError = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithGoogleStep2() - error');
            this.store.toggleSingingWithGoogleStep2(false);
            this.store.setSignWithGoogleStep2Error(parsedError);
          });
        });
        return throwError(() => parsedError);
      }),
      tap((user: UserModel | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithGoogleStep2() - done');
            this.store.toggleSingingWithGoogleStep2(false);
            this.userSignedIn(user);
          });
        });
      })
    );
  }

  private signWithAppleStep2(secret: string, name: string): Observable<UserModel | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('signWithAppleStep2()');
        this.store.toggleSingingWithAppleStep2(true);
        this.store.setSignWithAppleStep2Error(null);
      });
    });

    return this.authAPIService.signInWithApple(secret || '', name || '').pipe(
      catchError((error: unknown) => {
        const parsedError = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithAppleStep2() - error');
            this.store.toggleSingingWithAppleStep2(false);
            this.store.setSignWithAppleStep2Error(parsedError);
          });
        });
        return throwError(() => parsedError);
      }),
      tap((user: UserModel | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithAppleStep2() - done');
            this.store.toggleSingingWithAppleStep2(false);
            this.userSignedIn(user);
          });
        });
      })
    );
  }

  private signWithFacebookStep2(
    fbId: string,
    fbToken: string
  ): Observable<UserModel | null> {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('signWithFacebookStep2()');
        this.store.toggleSingingWithFacebookStep2(true);
        this.store.setSignWithFacebookStep2Error(null);
      });
    });

    return this.authAPIService.signInWithFacebook(fbId || '', fbToken || '').pipe(
      catchError((error: unknown) => {
        const parsedError = parseApiError(error);
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithFacebookStep2() - error');
            this.store.toggleSingingWithFacebookStep2(false);
            this.store.setSignWithFacebookStep2Error(parsedError);
          });
        });
        return throwError(() => parsedError);
      }),
      tap((user: UserModel | null) => {
        this.zone.run(() => {
          applyTransaction(() => {
            logAction('signWithFacebookStep2() - done');
            this.store.toggleSingingWithFacebookStep2(false);
            this.userSignedIn(user);
          });
        });
      })
    );
  }

  private setError(error: any | null): void {
    this.zone.run(() => {
      applyTransaction(() => {
        logAction('setError()');
        this.store.update({
          error: error,
          lastUpdated: new Date(),
        });
      });
    });
  }

  // Keep public so we can mock it on testing
  public clearSession(): Subscription {
    const subscriptions = new Subscription();
    this.zone.runOutsideAngular(() => {
      this.updateCookieSession(null);
    });

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('clearSession()');

        this.store.setSignWithAppleError(null);
        this.store.setSignWithGoogleError(null);
        this.store.setSignWithFacebookError(null);

        this.zone.runOutsideAngular(() => {
          resetStores({
            exclude: [
              'screen',
              'angular-router',
              'overlays',
              'addresses',
              'location',
              'cart',
              'products',
              'checkout',
            ],
          });

          if (typeof window !== 'undefined') {
            window.localStorage.removeItem('AkitaStores');
          }

          this.store.setUser(null);
          this.store.updateLastTokenRefresh(true);
          this.store.update({
            serverType: environment.api.url,
            error: null,
            refreshingToken: false,
            lastUpdated: new Date(),
          });

          this.store.toggleSigningInWithGoogleSDKLoaded(true);

          configureScope((scope: any) => {
            scope.setUser(null);
          });
        });
      });
    });

    return subscriptions;
  }

  private updateSession(data?: AuthTokenModel | null): Subscription {
    const out = new Subscription();
    const user = this.query.user;

    if (user) {
      if (!user.oauth) {
        user.oauth = {
          publicId: '',
          secret: '',
          token: null,
        };
      }
      user.oauth.token = data || null;
    }

    this.zone.run(() => {
      applyTransaction(() => {
        logAction('updateSession()');
        this.updateCookieSession(user);
        this.store.setUser(user);
        this.store.update({
          refreshingToken: false,
          lastUpdated: new Date(),
        });
      });
    });

    configureScope((scope: any) => {
      scope.setUser(
        user
          ? {
              id: user.id,
              username: user.firstName,
              email: user.email,
            }
          : null
      );
    });

    return out;
  }
}
