import { Injectable } from '@angular/core';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { Query } from '@datorama/akita';
import { environment } from '@environments/environment';
import { Observable, map, distinctUntilChanged } from 'rxjs';
import { AkitaPaymentState } from '../models/payment.state';
import { AkitaPaymentStore } from './payment.store';
import {
  parseTabbySessionApiResponse,
  TabbySessionApiResponse,
} from '@app/checkout/payment-providers/tabby/models/tabby-session.model';
import {
  TamaraPaymentOption,
  parseTamaraPaymentOptionList,
} from '@app/checkout/payment-providers/tamara/models/tamara-payment-option.model';

@Injectable({ providedIn: 'root' })
export class AkitaPaymentQuery extends Query<AkitaPaymentState> {
  constructor(protected store: AkitaPaymentStore) {
    super(store);
  }

  public get tabbyPublicKey(): string {
    return `${this.getValue()?.tabbyPublicKey || environment.tabby.pubKey || ''}`;
  }

  public get tamaraPublicKey(): string {
    return `${this.getValue()?.tamaraPublicKey || environment.tamara.pubKey || ''}`;
  }

  public get checkoutDotComPublicKey(): string {
    return `${this.getValue()?.checkoutDotComPublicKey}`;
  }

  public getTabbySession(listingId?: string | null): TabbySessionApiResponse | null {
    if (listingId) {
      const sessions = this.getValue()?.tabbySession || {};
      return parseTabbySessionApiResponse(sessions[listingId]);
    }
    return null;
  }

  public getTamaraPaymentOptions(
    countryCode?: string | null
  ): Array<TamaraPaymentOption> {
    if (countryCode) {
      const paymentOptions = this.getValue()?.tamaraPaymentOptions || {};
      return parseTamaraPaymentOptionList(paymentOptions[countryCode]);
    }
    return [];
  }

  public selectErrorFetchingTamaraPaymentOptions(
    country: string
  ): Observable<ApiError | null> {
    return this.select().pipe(
      map((state: AkitaPaymentState) => state.errorFetchingTamaraPaymentOptions[country]),
      distinctUntilChanged()
    );
  }

  public getStripeKey(currency?: string | null): string {
    let useCurrency = 'USD';
    if (currency === 'BRL' || this.currency === 'BRL') {
      useCurrency = 'BRL';
    }
    return this.getValue().apiTokens[useCurrency]?.stripeApiToken || '';
  }

  public getStripeKeyFromEnviroment(currency?: string | null): string {
    let token = environment.stripe.country.us;
    if (`${currency || this.currency || ''}`.toUpperCase() === 'BRL') {
      token = environment.stripe.country.br;
    }
    return token;
  }

  public getStripeKeyWithFallback(currency?: string | null): string {
    return this.getStripeKey(currency) || this.getStripeKeyFromEnviroment(currency);
  }

  public get setupIntentSecret(): string {
    return this.getSetupIntentSecret();
  }

  public getSetupIntentSecret(currency?: string | null): string {
    let useCurrency = 'USD';
    if (currency === 'BRL' || this.currency === 'BRL') {
      useCurrency = 'BRL';
    }

    return this.getValue().setupIntentSecrets[useCurrency] || '';
  }

  public isSetupIntentSecretLoading(currency?: string | null): boolean {
    return Boolean(this.getValue().gettingSetupIntentSecrets[currency || 'SAR']);
  }

  public get currency(): string {
    return this.getValue().currency || '';
  }

  public getGeneratingTabbySession(productsIds: string): boolean {
    return Boolean(this.getValue().generatingTabbySession[productsIds] || false);
  }

  public selectStripeApiToken(currency?: string | null): Observable<string> {
    return this.select().pipe(
      map((state: AkitaPaymentState) => {
        let useCurrency = 'USD';
        if (currency === 'BRL' || this.currency === 'BRL') {
          useCurrency = 'BRL';
        }
        return state.apiTokens[useCurrency]?.stripeApiToken || '';
      }),
      distinctUntilChanged()
    );
  }

  public selectCurrency(): Observable<string> {
    return this.select().pipe(
      map((state: AkitaPaymentState) => state.currency || ''),
      distinctUntilChanged()
    );
  }

  public selectSetupIntentSecretLoading(currency?: string | null): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaPaymentState) => {
        let useCurrency = 'USD';
        if (currency === 'BRL' || this.currency === 'BRL') {
          useCurrency = 'BRL';
        }
        return Boolean(state.gettingSetupIntentSecrets[useCurrency]);
      }),
      distinctUntilChanged()
    );
  }

  public selectSetupIntentSecret(currency?: string | null): Observable<string> {
    return this.select().pipe(
      map(
        (state: AkitaPaymentState) => state.setupIntentSecrets[currency || 'SAR'] || ''
      ),
      distinctUntilChanged()
    );
  }

  public selectSetupIntentSecretError(
    currency?: string | null
  ): Observable<ApiError | null> {
    return this.select().pipe(
      map((state: AkitaPaymentState) => {
        let useCurrency = 'USD';
        if (currency === 'BRL' || this.currency === 'BRL') {
          useCurrency = 'BRL';
        }
        return state.gettingSetupIntentSecretsError[useCurrency] || null;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectTabbyPublicKey(): Observable<string> {
    return this.select().pipe(
      map(
        (state: AkitaPaymentState) =>
          `${state.tabbyPublicKey || environment.tabby.pubKey || ''}`
      ),
      distinctUntilChanged()
    );
  }

  public selectCheckoutDotComPublicKey(): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaPaymentState) => state.checkoutDotComPublicKey),
      distinctUntilChanged()
    );
  }

  public getTabbySessionRejectionReason(
    productIds?: Array<string> | null
  ): string | null {
    const parsedproductIds = productIds?.join('-');
    if (parsedproductIds?.length) {
      const sessions = this.getValue()?.tabbySession || {};
      return sessions[parsedproductIds]?.configuration?.rejectionReason || null;
    }
    return null;
  }

  public selectTabbySession(
    productIds?: Array<string> | null
  ): Observable<TabbySessionApiResponse | null> {
    return this.select().pipe(
      map((state: AkitaPaymentState) => {
        const parsedproductIds = productIds?.join('-');
        if (parsedproductIds?.length) {
          const sessions = state?.tabbySession || {};
          return parseTabbySessionApiResponse(sessions[parsedproductIds]);
        }
        return null;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectTamaraPaymentOptions(
    countryCode?: string | null
  ): Observable<Array<TamaraPaymentOption>> {
    return this.select().pipe(
      map((state: AkitaPaymentState) => {
        if (countryCode) {
          const sessions = state?.tamaraPaymentOptions || {};
          return parseTamaraPaymentOptionList(sessions[countryCode]);
        }
        return [];
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectIsGeneratingTabbySession(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaPaymentState) =>
        Boolean(
          Object.keys(state.generatingTabbySession).find(
            (x) => state.generatingTabbySession[x] === true
          )
        )
      ),
      distinctUntilChanged()
    );
  }

  public selectIsFetchingTamaraPaymentOptions(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaPaymentState) =>
        Boolean(
          Object.keys(state.fetchingTamaraPaymentOptions).find(
            (x) => state.fetchingTamaraPaymentOptions[x] === true
          )
        )
      ),
      distinctUntilChanged()
    );
  }
}
