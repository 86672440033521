import { parseTabbyMerchantUrls, TabbyMerchantUrls } from './tabby-configuration.model';
import { parseTabbyPayment, TabbyPayment } from './tabby-payment.model';
import { parseTabbyProducts, TabbyProducts } from './tabby-products.model';

export class TabbySessionApiResponse {
  public id: string;
  public status: string;
  public payment: TabbyPayment;
  public merchantUrls: TabbyMerchantUrls;
  public configuration: {
    currency: string;
    newCustomer: boolean;
    availableProducts: TabbyProducts;
    rejectionReason?: string | null;
  };

  constructor() {
    this.id = '';
    this.status = '';
    this.payment = new TabbyPayment();
    this.merchantUrls = new TabbyMerchantUrls();
    this.configuration = {
      currency: 'SAR',
      newCustomer: true,
      availableProducts: new TabbyProducts(),
    };
  }
}

export const parseTabbySessionApiResponse = (
  data?: Record<string, any> | null
): TabbySessionApiResponse | null => {
  let result: TabbySessionApiResponse | null = null;
  if (data) {
    result = new TabbySessionApiResponse();
    result.id = data.id || '';
    result.status = data.status || '';
    result.payment = parseTabbyPayment(data.payment) || new TabbyPayment();
    result.merchantUrls =
      parseTabbyMerchantUrls(data.merchant_urls || data.merchantUrls) ||
      new TabbyMerchantUrls();
    result.configuration = {
      currency: data.configuration?.currency || 'SAR',
      newCustomer: Boolean(
        data.configuration?.new_customer || data.configuration?.newCustomer
      ),
      availableProducts:
        parseTabbyProducts(
          data.configuration?.available_products || data.configuration?.availableProducts
        ) || new TabbyProducts(),
    };

    if (data.status === 'REJECTED') {
      result.configuration.rejectionReason =
        data.configuration?.products?.installments?.rejection_reason ||
        data.configuration.rejectionReason ||
        null;
    }
  }
  return result;
};
